import React from "react";
import Modeler from "bpmn-js/lib/Modeler";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn.css";
import { post, get, put, exe, safeParse, safeGet } from "../Lib/Dal";

import {
  AppstoreOutlined,
  DownloadOutlined,
  EditOutlined,
  FileOutlined,
  FolderOpenOutlined,
  InboxOutlined,
  SaveOutlined,
  SearchOutlined,
  SettingOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import { Menu, Modal, Input, Table, message, Drawer, Button, Select, InputNumber } from "antd";
import ProcesosDetalle from "./ProcesosDetalle";
import showPrompt from "./Shared/showPrompt";
import Title from "antd/lib/typography/Title";
import FileUpload from "./Life/FileUpload";
import Dragger from "antd/lib/upload/Dragger";
import UserSelect from "./Bpm/UserSelect";
import { withTranslation } from "react-i18next";

const { Column, ColumnGroup } = Table;
const Option = Select.Option;
const { TextArea } = Input;

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
let xml = `<?xml version="1.0" encoding="UTF-8"?>
        <bpmn2:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd" id="sample-diagram" targetNamespace="http://bpmn.io/schema/bpmn">
          <bpmn2:process id="Process_1" isExecutable="false">
            <bpmn2:startEvent id="StartEvent_1"/>
          </bpmn2:process>
          <bpmndi:BPMNDiagram id="BPMNDiagram_1">
            <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1">
              <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
                <dc:Bounds height="36.0" width="36.0" x="412.0" y="240.0"/>
              </bpmndi:BPMNShape>
            </bpmndi:BPMNPlane>
          </bpmndi:BPMNDiagram>
        </bpmn2:definitions>`;
class Procesos extends React.Component {
  state = {
    modalNombre: false,
    modalCargar: false,
    propiedadesVisible: false,
    detalleVisible: false,
    selected: [],
    elementoSeleccionado: false,
    data: [],
    userActionsElement: [],
    entityStateElement: undefined,
    importVisibles: false,
    searchText: "",
    searchedColumn: "",
  };
  forms = [];
  grupos = [];
  actions = [];
  userActions = [
    { code: "QUOTE", name: "QUOTE" },
    { code: "ISSUE", name: "ISSUE" },
    { code: "CHANGES", name: "CHANGES" },
    { code: "REINSTATEMENT", name: "REINSTATEMENT" },
  ];
  entityStates = [
    { code: "DRAFT", name: "DRAFT", entity: "LIFEPOLICY" },
    { code: "ACTIVE", name: "ACTIVE", entity: "LIFEPOLICY" },
    { code: "INACTIVE", name: "INACTIVE", entity: "LIFEPOLICY" },
    { code: "VALIDATION", name: "VALIDATION", entity: "CLAIM" },
    { code: "ADJUDICATION", name: "ADJUDICATION", entity: "CLAIM" },
    { code: "CLOSURE", name: "CLOSURE", entity: "CLAIM" },
    { code: "CLOSED", name: "CLOSED", entity: "CLAIM" },
    { code: "REQUESTED", name: "REQUESTED", entity: "PERMISSION" },
    { code: "APROVED", name: "APROVED", entity: "PERMISSION" },
    { code: "REJECTED", name: "REJECTED", entity: "PERMISSION" },
    { code: "PENDING", name: "PENDING", entity: "ClaimPayment" },
    { code: "APROVED", name: "APROVED", entity: "ClaimPayment" },
    { code: "REJECTED", name: "REJECTED", entity: "ClaimPayment" },
    { code: "OPEN", name: "Open", entity: "DEFAULT" },
    { code: "INPROGRESS", name: "In Progress", entity: "DEFAULT" },
    { code: "CLOSED", name: "Closed", entity: "DEFAULT" },
    { code: "APROVED", name: "Aproved", entity: "DEFAULT" },
    { code: "REJECTED", name: "Rejected", entity: "DEFAULT" },
  ];

  loadData() {
    exe("GetDefinitions").then((r) => {
      this.setState({ data: r.outData });
    });
  }
  loadForms() {
    exe("GetForms").then((r) => {
      this.forms = r.outData;
    });
    exe("RepoEntityAction", { operation: "GET" }).then((r) => {
      this.actions = r.outData;
    });

    exe("RepoUsrGroup", { operation: "GET" }).then((r) => {
      this.grupos = r.outData;
    });

    // get('/api/grupo').then(r=>{
    //   this.grupos=r;
    // })
  }
  componentDidMount() {
    this.loadData();
    this.loadForms();

    this.modeler = new Modeler({ container: "#canvas", width: "100%", height: "100%" });
    const modeler = this.modeler;
    const me = this;

    //viene desde launcher
    if (this.props.location.proceso) {
      const p = this.props.location.proceso;
      xml = p.xml;
      this.setState({ selected: [p.id] });
    }

    this.modeler.importXML(xml, function (err) {
      if (err) {
        console.log(err, "ERROR");
      } else {
        var canvas = modeler.get("canvas");
        canvas.zoom("fit-viewport");
      }
    });
    window.mymodeler = this.modeler; //pruebas

    this.modeler.on("element.click", function (event) {
      console.log(event);
      me.setState({ element: event.element, elementoSeleccionado: true });
    });
  }

  nuevo = () => {
    const modeler = this.modeler;
    const me = this;
    modeler.importXML(xml, function (err) {
      if (err) {
        console.log(err, "ERROR");
      } else {
        var canvas = modeler.get("canvas");
        canvas.zoom("fit-viewport");
        me.setState({ selected: [] });
      }
    });
  };
  cargar() {
    this.setState({ modalCargar: true });
  }
  editar() {
    const modeler = this.modeler;
    modeler.getDefinitions().rootElements[0].flowElements[1].name = "prueba";
    modeler.importDefinitions(modeler.getDefinitions(), () => console.log("hecho"));
  }
  onRename() {
    const me = this;
    showPrompt({
      title: this.props.t("Please enter new name"),
      placeholder: this.props.t("New name"),
      rules: [
        {
          required: true,
          message: this.props.t("You must enter name"),
        },
      ],
    })
      .then((newName) => {
        const definicion = me.state.data.find((d) => d.id == me.state.selected[0]);
        definicion.name = newName;

        exe("AddOrUpdateDefinition", definicion, { operation: "UPDATE" }).then((r) => {
          if (r.ok) {
            message.success(this.props.t("Changes saved"));
          } else {
            message.error(this.props.t("Error saving"));
          }
        });
      })
      .catch((e) => console.error(e));
  }
  handleClick = (e) => {
    switch (e.key) {
      case "guardar":
        this.guardar();
        break;
      case "guardarComo":
        this.guardarComo();
        break;
      case "nuevo":
        this.nuevo();
        break;
      case "cargar":
        this.cargar();
        break;
      case "editar":
        this.editar();
        break;
      case "opciones":
        this.onOpciones();
        break;
      case "detalle":
        this.onClickDetalle();
        break;
      case "rename":
        this.onRename();
      case "import":
        this.setState({ importVisible: true });
        break;
      case "export":
        this.onExport();
      default:
        break;
    }
  };
  onClickDetalle() {
    this.setState({ detalleVisible: true });
  }
  onOpciones() {
    this.setState({
      propiedadesVisible: true,
      nombreElemento: this.state.element.businessObject.name,
      formaElemento: +this.state.element.businessObject.$attrs.form,
      actionElemento: this.state.element.businessObject.$attrs.action,
      userActionsElement: safeParse(this.state.element.businessObject.$attrs.userActions) || [],
      entityStateElement: this.state.element.businessObject.$attrs.entityState,
      grupoElemento: +this.state.element.businessObject.$attrs.grupo,
      responsibleElemento: this.state.element.businessObject.$attrs.responsible,
      slaElemento: +this.state.element.businessObject.$attrs.sla,
      condicionGateway: this.state.element.businessObject.$attrs.condicion,
      script: this.state.element.businessObject.script,
      clientScript: this.state.element.businessObject.$attrs.clientScript,
      ruleId: +this.state.element.businessObject.$attrs.ruleId,
      entityStateReason: this.state.element.businessObject.$attrs.entityStateReason,
    });
  }
  onExport() {
    this.modeler.saveXML({ format: true }, (err, xml) => {
      const textFileAsBlob = new Blob([xml], { type: "text/plain" });
      const fileNameToSaveAs = "sis11Process.bpmn";
      const downloadLink = document.createElement("a");
      downloadLink.download = fileNameToSaveAs;
      downloadLink.innerHTML = "Download File";
      if (window.webkitURL != null) {
        // Chrome allows the link to be clicked
        // without actually adding it to the DOM.
        downloadLink.href = window.webkitURL.createObjectURL(textFileAsBlob);
      } else {
        // Firefox requires the link to be added to the DOM
        // before it can be clicked.
        downloadLink.href = window.URL.createObjectURL(textFileAsBlob);
        //downloadLink.onclick = destroyClickedElement;
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
      }

      downloadLink.click();
    });
  }
  guardarComo() {
    this.setState({ modalNombre: true });
  }
  handleOkModal() {
    const me = this;
    this.modeler.saveXML({ format: true }, (err, xml) => {
      const definition = { name: this.state.nombreProceso, xml: xml };

      exe("AddOrUpdateDefinition", definition).then((r) => {
        message.success("Process Saved");
        me.loadData();
      });
    });
    this.setState({ modalNombre: false, nombreProceso: "" });
  }
  handleCancelModal() {
    this.setState({ modalNombre: false });
  }
  handleOkModalCargar() {
    const miXML = this.state.data.find((p) => p.id == this.state.selected[0]);
    const modeler = this.modeler;
    const me = this;
    modeler.importXML(miXML.xml, function (err) {
      if (err) {
        console.log(err, "ERROR");
      } else {
        var canvas = modeler.get("canvas");
        canvas.zoom("fit-viewport");
        //me.setState({ modalCargar: false });
        console.log(miXML.xml);
      }
    });
  }
  handleCancelModalCargar() {
    this.setState({ modalCargar: false });
  }
  guardar = () => {
    const me = this;
    if (this.state.selected.length == 0) {
      this.guardarComo();
    } else {
      this.modeler.saveXML({ format: true }, function (err, xml) {
        let definicion = me.state.data.find((d) => d.id == me.state.selected[0]);
        definicion.xml = xml;
        definicion.id = definicion.id;

        exe("AddOrUpdateDefinition", definicion).then((r) => {
          if (r.ok) {
            message.success(me.props.t("Changes saved"));
            me.loadData();
          } else {
            message.error(me.props.t("Error saving"));
          }
        });
      });
    }
  };
  handleImport = (file, fileList) => {
    const reader = new FileReader();
    const me = this;
    reader.onload = (e) => {
      const modeler = me.modeler;
      modeler.importXML(e.target.result, function (err) {
        if (err) {
          message.error(this.props.t("Error importing file. Please cehck format. More info in console."));
          console.log(err);
        } else {
          me.setState({ importVisible: false });
          var canvas = modeler.get("canvas");
          canvas.zoom("fit-viewport");
        }
      });
    };
    reader.readAsText(file);
    return false;
  };
  onClosePropiedades = () => {
    this.setState({
      propiedadesVisible: false,
    });
  };

  asigna(valor, atributo, objeto) {
    if (valor != objeto[atributo]) {
      if (valor) {
        objeto[atributo] = valor;
      } else {
        delete objeto[atributo];
      }
    }
  }

  guardarElemento = () => {
    let elem = this.state.element;

    this.asigna(this.state.nombreElemento, "name", elem.businessObject);
    this.asigna(this.state.formaElemento, "form", elem.businessObject.$attrs);
    this.asigna(this.state.condicionGateway, "condicion", elem.businessObject.$attrs);
    this.asigna(this.state.grupoElemento, "grupo", elem.businessObject.$attrs);
    this.asigna(this.state.responsibleElemento, "responsible", elem.businessObject.$attrs);
    this.asigna(this.state.slaElemento, "sla", elem.businessObject.$attrs);
    this.asigna(this.state.actionElemento, "action", elem.businessObject.$attrs);
    this.asigna(JSON.stringify(this.state.userActionsElement), "userActions", elem.businessObject.$attrs);
    this.asigna(this.state.entityStateElement, "entityState", elem.businessObject.$attrs);
    this.asigna(this.state.entityStateReason, "entityStateReason", elem.businessObject.$attrs);

    elem.businessObject.script = this.state.script;
    this.asigna(this.state.clientScript, "clientScript", elem.businessObject.$attrs); //available in all types, thus asigning to attribute
    this.asigna(this.state.ruleId, "ruleId", elem.businessObject.$attrs);

    this.setState({ element: elem, propiedadesVisible: false, nombreElemento: null });

    this.modeler.importDefinitions(this.modeler.getDefinitions(), () => console.log("hecho"));
  };
  okModalDetalle = (r) => {
    const index = this.state.data.findIndex((p) => p.id == r.id);
    const data = this.state.data;

    if (r.delete) {
      //delete data[index];
      data.splice(index, 1);
      this.nuevo();
      this.setState({ detalleVisible: false, data: data, selected: [] });
    } else {
      data[index] = r;
      this.setState({ detalleVisible: false, data: data });
    }
  };
  cancelModalDetalle = () => {
    this.setState({ detalleVisible: false });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(ele) => (this.searchInput = ele)}
          placeholder={`${this.props.t("Search")} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(this.state.selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}>
          {this.props.t("Search")}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {this.props.t("Reset")}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined, fontSize: 15 }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({ searchText: selectedKeys ? selectedKeys[0] : "", searchedColumn: dataIndex });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  render() {
    const { t } = this.props;
    const definicion = this.state.data && this.state.selected[0] && this.state.data.find((p) => p.id == this.state.selected[0]);
    let filteredEntityStates = (definicion && this.entityStates.filter((p) => p.entity == definicion.entity)) || [];
    if (filteredEntityStates.length == 0) {
      filteredEntityStates = (definicion && this.entityStates.filter((p) => p.entity == "DEFAULT")) || [];
    }
    return (
      <div>
        <Menu style={{ marginTop: "-10px", marginBottom: "20px" }} mode="horizontal" onClick={this.handleClick} selectable={false}>
          <SubMenu
            title={
              <span className="submenu-title-wrapper">
                <AppstoreOutlined />
                {t("File")}
              </span>
            }>
            <MenuItemGroup title="Processes">
              <Menu.Item key="nuevo">
                <FileOutlined />
                {t("New")}
              </Menu.Item>
              <Menu.Item key="cargar">
                <FolderOpenOutlined />
                {t("Open")}
              </Menu.Item>
              <Menu.Item key="guardar">
                <SaveOutlined />
                {t("Save")}
              </Menu.Item>
              <Menu.Item key="guardarComo">
                <SaveOutlined />
                {t("Save As...")}
              </Menu.Item>
              <Menu.Item key="rename">
                <EditOutlined />
                {t("Rename")}
              </Menu.Item>
              <Menu.Item key="import">
                <UploadOutlined />
                {t("Import")}
              </Menu.Item>
              <Menu.Item key="export">
                <DownloadOutlined />
                {t("Export")}
              </Menu.Item>
            </MenuItemGroup>
          </SubMenu>
          <Menu.Item key="opciones" disabled={!this.state.elementoSeleccionado}>
            <SettingOutlined />
            {this.state.element ? this.state.element.type : t("Options")}
          </Menu.Item>

          <Menu.Item key="detalle" disabled={!definicion}>
            <EditOutlined />
            {t("Properties")}
          </Menu.Item>
        </Menu>
        <Title level={4}>{definicion ? definicion.id + "." + definicion.name : null} </Title>
        <Modal
          title={t("Save Process Definition")}
          visible={this.state.modalNombre}
          onOk={() => this.handleOkModal()}
          onCancel={() => this.handleCancelModal()}>
          <Input placeholder={t("Insert name")} onChange={(v) => this.setState({ nombreProceso: v.target.value })} />
        </Modal>

        <Modal
          title={t("Load Process Definition")}
          visible={this.state.modalCargar}
          forceRender
          onOk={() => this.handleCancelModalCargar()}
          onCancel={() => this.handleCancelModalCargar()}>
          <Table
            rowKey="id"
            scroll={{ y: 400 }}
            dataSource={this.state.data}
            pagination={false}
            rowSelection={{
              columnTitle: <></>,
              selectedRowKeys: this.state.selected,
              onSelect: (record) => {
                this.setState({ selected: [record.id] }, () => this.handleOkModalCargar());
              },
            }}
            onRow={(record) => ({
              onClick: () => {
                this.setState({ selected: [record.id] }, () => this.handleOkModalCargar());
              },
            })}>
            <Column title={t("Id")} dataIndex="id" width={60} {...this.getColumnSearchProps("id")} />
            <Column title={t("Name")} dataIndex="name" style={{ width: "100%" }} {...this.getColumnSearchProps("name")} />
          </Table>
        </Modal>

        <Modal
          title={t("Process Detail")}
          destroyOnClose
          visible={this.state.detalleVisible}
          onOk={() => this.formDetalle.handleSubmit()}
          onCancel={() => this.cancelModalDetalle()}>
          <ProcesosDetalle wrappedComponentRef={(form) => (this.formDetalle = form)} onOk={(r) => this.okModalDetalle(r)} definicion={definicion} />
        </Modal>

        {this.state.element && (
          <Drawer title={this.state.element.type} placement="right" onClose={this.onClosePropiedades} visible={this.state.propiedadesVisible} width={500}>
            <div style={{ lineHeight: 2 }}>
              {t("Name")}
              <Input placeholder={t("Element Name")} value={this.state.nombreElemento} onChange={(v) => this.setState({ nombreElemento: v.target.value })} />
              {t("Form")}
              <Select
                placeholder={t("Element Form")}
                allowClear={true}
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={this.state.formaElemento ? this.state.formaElemento : undefined}
                onChange={(v) => this.setState({ formaElemento: v })}>
                {this.forms.map((p) => (
                  <Option key={p.id} value={p.id}>{p.name}</Option>
                ))}
              </Select>
              {t("User Action")}
              <Select
                placeholder={t("Select User Action")}
                mode="multiple"
                style={{ width: "100%" }}
                showArrow
                value={this.state.userActionsElement}
                onSelect={(v) => this.setState({ userActionsElement: [...this.state.userActionsElement, v] })}
                onDeselect={(v) => this.setState({ userActionsElement: this.state.userActionsElement.filter((p) => p !== v) })}>
                {this.userActions.map((p) => (
                  <Option value={p.code}>{p.name}</Option>
                ))}
              </Select>
              {t("Entity State")}
              <Select
                placeholder={t("Select Entity State")}
                style={{ width: "100%" }}
                showArrow
                value={this.state.entityStateElement}
                onSelect={(v) => this.setState({ entityStateElement: v })}
                onDeselect={(v) => this.setState({ entityStateElement: undefined })}>
                {filteredEntityStates.map((p) => (
                  <Option value={p.code}>{p.name}</Option>
                ))}
              </Select>
              {t("Entity State Reason")}
              <TextArea
                placeholder={t("Entity state reason expression")}
                value={this.state.entityStateReason}
                onChange={(v) => this.setState({ entityStateReason: v.target.value })}
              />
              {t("Condition")}
              <TextArea
                placeholder={t("Conditional expression")}
                disabled={
                  this.state.element.type != "bpmn:ExclusiveGateway" &&
                  this.state.element.type != "bpmn:StartEvent" &&
                  this.state.element.type != "bpmn:BoundaryEvent"
                }
                value={this.state.condicionGateway}
                onChange={(v) => this.setState({ condicionGateway: v.target.value })}
              />
              {t("Command Action")}
              <Select
                placeholder={t("Select Command Action")}
                disabled={this.state.element.type != "bpmn:ScriptTask"}
                allowClear={true}
                style={{ width: "100%" }}
                value={this.state.actionElemento}
                onSelect={(v) => this.setState({ actionElemento: v, script: this.actions.find((p) => p.code == v).cmd })}
                onChange={(v) => (!v ? this.setState({ actionElemento: undefined, script: null }) : true)}>
                {this.actions.map((p) => (
                  <Option value={p.code}>{p.name}</Option>
                ))}
              </Select>
              <div>
                {t("Script")}
                <TextArea
                  placeholder={t("Custom Script")}
                  disabled={this.state.element.type != "bpmn:ScriptTask"}
                  value={this.state.script}
                  onChange={(v) => this.setState({ script: v.target.value })}
                />
              </div>
              <div>
                {t("Client Script")}
                <TextArea
                  placeholder={t("Custom Client Script")}
                  value={this.state.clientScript}
                  onChange={(v) => this.setState({ clientScript: v.target.value })}
                />
              </div>
              <div>
                {t("Decision Table")}
                <Input
                  placeholder={t("Decision Table Id")}
                  disabled={this.state.element.type != "bpmn:BusinessRuleTask"}
                  value={this.state.ruleId}
                  onChange={(v) => this.setState({ ruleId: v.target.value })}
                />
              </div>
              {t("Responsible Team")}
              <Select
                placeholder={t("Responsible Team")}
                allowClear={true}
                style={{ width: "100%" }}
                value={this.state.grupoElemento ? this.state.grupoElemento : undefined}
                onChange={(v) => this.setState({ grupoElemento: v, responsibleElemento: undefined })}>
                {this.grupos.map((p) => (
                  <Option value={p.id}>{p.name}</Option>
                ))}
              </Select>
              {t("Responsible User")}
              <UserSelect
                originating
                onChange={(v) => this.setState({ responsibleElemento: v, grupoElemento: undefined })}
                value={this.state.responsibleElemento ? this.state.responsibleElemento : undefined}
              />
              {t("SLA")}
              <InputNumber
                placeholder={t("SLA (h)")}
                style={{ width: "100%" }}
                min={0}
                value={this.state.slaElemento ? this.state.slaElemento : undefined}
                onChange={(v) => this.setState({ slaElemento: v })}
              />
              <Button onClick={this.guardarElemento} type="primary" style={{ marginTop: 10 }}>
                {t("Accept")}
              </Button>
            </div>
          </Drawer>
        )}

        <div id="canvas" style={{ width: "100%", height: "100%", overflow: "hidden", position: "fixed" }}></div>
        <div id="properties"></div>
        <Modal title={t("Import")} visible={this.state.importVisible} onCancel={() => this.setState({ importVisible: false })}>
          <Dragger
              name= "file"
              beforeUpload={this.handleImport}
              fileList={[]}
            >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t("Click or drag file to this area to upload")}</p>
            <p className="ant-upload-hint">{t("Support for bpmn files")}</p>
          </Dragger>
        </Modal>
      </div>
    );
  }
}
export default withTranslation()(Procesos);
